import React from "react"
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;

  @media(min-width: 945px) {
    max-width: 976px;
    width: 100%;
    margin: 0 auto;
  }
`;

export default ({ children }) => (
  <Wrapper>
    { children }
  </Wrapper>
)
