import React from "react"

import Layout from '../components/layout';

export default () => (
  <div style={{ color: `teal` }}>
    <Layout> 
      Call me reza
    </Layout>
  </div>
)
