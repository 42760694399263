import React from "react"

import Layout from '../components/layout';
import Intro from '../components/intro';
import Facade from '../components/facade';

import highlights from '../data/hightlights';
import projects from '../data/projects';
import writings from '../data/writings';

export default () => (
  <div>
    <Layout> 
      <Intro />
      {highlights && highlights.length > 1 && (
        <Facade  title="Highlights" windows={highlights}/>
      )}
      {projects && projects.length > 1 && (
        <Facade  title="Projects" windows={projects}/>
      )}
      {writings && writings.length > 1 && (
        <Facade  title="Explorations" windows={writings}/>
      )}
    </Layout>
  </div>
)
