const socials = [
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/rezafaizarahman/",
  },
  {
    title: "Dribble",
    url: "http://dribbble.com/rezafaizarahman",
  },
  {
    title: "Behance",
    url: "https://behance.net/rezafaizarahman",
  },
  {
    title: "Github",
    url: "http://github.com/rezafaizarahman",
  },
  {
    title: "Twitter",
    url: "http://twitter.com/rezafaizarahman",
  },
]

export default socials;