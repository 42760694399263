const writings = [
  {
    imgUrl: "https://cldup.com/27LYcQq6dI.png",
    title: "Multiple homepage",
    caption:
      "Sheets pada homepage, kamu dapat menampilkan konten yang memiliki hirarki sama dengan mudah.",
    url: "https://dribbble.com/shots/6478279-01-Multiple-Homepage",
  },
  {
    imgUrl:
      "https://cdn.dribbble.com/users/717680/screenshots/6493385/micro_app_dock_-_rezafaizarahman.gif",
    title: "Micro app sticky dock",
    caption:
      "Dock dapat membuat pengguna lebih mudah mengakses micro app sambil melakukan scroll.",
    url: "https://dribbble.com/shots/6493385-02-Micro-App-Dock",
  },
  {
    imgUrl:
      "https://cdn.dribbble.com/users/717680/screenshots/6496658/dynamic-scroll-navbar-rezafaizarahman.gif",
    title: "Dynamic scroll navigation bar",
    caption:
      "Dynamic navigation dapat menampilkan konten tanpa menghilangkan fungsi saat melakukan scroll. ",
    url: "https://dribbble.com/shots/6496658-03-Dynamic-Scroll-Navigation-Bar",
  },
  {
    imgUrl:
      "https://cdn.dribbble.com/users/717680/screenshots/6539639/separate-section-long-form-rezafaizarahman.gif",
    title: "Section long form",
    caption: "Coming Soon",
    url: "https://dribbble.com/shots/6539639-04-Section-Long-Form",
  },
]

export default writings;