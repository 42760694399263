import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/contentWrapper';

const Wrapper = styled.section`
  background-image: url('./gelembung-gede.svg');
  background-position: bottom;
  background-size: cover;
  padding-top: 124px;
  height: 540px;
  color: #fff;
  
  @media(min-width: 945px) {
    height: 480px;
    padding-top: 160px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 48px;
  padding: 0 24px;

  @media(min-width: 945px) {
    font-size: 48px;
    padding: 0;
  }
`

const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0 24px;
  
  @media(min-width: 945px) {
    font-size: 20px;
    line-height: 32px;
    max-width: 624px;
    padding: 0;
  }
`

const Intro = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>Reza Faiz A. Rahman</Title>
        <SubTitle>I am a designer who passionate about design system and design tools. Currently designing components and build design tools for Bukalapak.</SubTitle>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Intro;
