import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/contentWrapper';
import WindowCard from '../components/windowCard';

const Wrapper = styled.section`
  background-image: url('./gelembung-kecil.svg');
  background-position: center;
  background-size: cover;
  padding-bottom: 64px;
  background-repeat: no-repeat;

  @media(min-width: 945px) {
    background-size: contain;
  }
`;

const Title = styled.h3`
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
`

const WindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  > a {
    margin-bottom: 24px;
  }

  @media(min-width: 945px) {
    flex-direction: row;
    margin: 0 -12px;
    flex-wrap: wrap;

    > a {
      flex: 1 1 218px;
      margin: 0 12px 24px 12px;
    }
  }
`

const Facade = ({ title, windows }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <WindowWrapper>
          {windows.map((window, i) => (
            <WindowCard key={i} {...window} />
          ))}
        </WindowWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Facade;
