import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/contentWrapper';
import socials from '../data/socials';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  height: 64px;
  background: #282F57;
  overflow: scroll;
`;

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  margin-right: 32px;
  text-decoration: none;
  color: #FAC190;
`;

const Footer = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        {socials.map((social, i) => (
          <StyledLink key={i} href={social.url}>{social.title}</StyledLink>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
}

export default Footer;
