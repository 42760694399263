const hightlights = [
  {
    imgUrl: "/tigapuluh-project.png",
    title: "Tiga puluh challenge",
    caption:
      "Tiga Puluh merupakan tantangan membuat desain dalam waktu 30 hari. Desain dibuat menggunakan Sketch, Invision Studio maupun Framer.",
    url: "https://dribbble.com/tags/tigapuluh",
  },
  {
    imgUrl: "/konmari.png",
    title: "Decluttering bukalapak’s style guide using the KonMari method",
    caption:
      "Tidying up requires commitment, diligence, and a lot of effort. This is the story of how I tried to tidying up Bukalapak's style guide using the KonMari magical method.",
    url:
      "https://medium.com/bukalapakproductdesign/decluttering-bukalapaks-style-guide-using-the-konmari-method-b8da17400bc1",
  },
]

export default hightlights;