import React from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';

import ContentWrapper from '../components/contentWrapper';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  height: 64px;
  background: #282F57;
  padding: 0 24px;

  @media(min-width: 945px) {
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
  margin-right: 32px;
  text-decoration: none;
  color: white;
`;

const Header = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <StyledLink to="/">Home</StyledLink>
        {/* <StyledLink to="/about/">About</StyledLink> */}
        <StyledLink to="/resume-rezafaizarahman-december-2018.pdf">
          Resume
        </StyledLink>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Header;
