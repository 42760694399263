const projects = [
  {
    imgUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7f339f60953299.5a5eaa5405059.png",
    title: "Sparing",
    caption:
      "Sparing merupakan aplikasi untuk menghubungkan para pemain futsal dengan pemilik stadion futsal.",
    url: "https://www.behance.net/gallery/60953299/Sparing-(Mobile-App)",
  },
  {
    imgUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/49ad0860858923.5a5bf73245ef3.png",
    title: "Loenpia Jazz 2015 - Web",
    caption: "Loenpia Jazz merupakan Festival musik Jazz terbesar di Semarang.",
    url: "https://www.behance.net/gallery/60858923/Loenpia-Jazz-2015-(Website)",
  },
  {
    imgUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4a378460858811.5a5bf5c9eeeae.png",
    title: "Loenpia Jazz 2015 - Apps",
    caption: "Loenpia Jazz merupakan Festival musik Jazz terbesar di Semarang.",
    url: "https://www.behance.net/gallery/60858923/Loenpia-Jazz-2015-(Website)",
  },
  {
    imgUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b3f24360858759.5a5bf4ff24296.png",
    title: "Lapor Gub!",
    caption:
      "Lapor Gub! merupakan platform untuk melaporkan suatu perihal kepada Gubernur dan Dinas terkait di Jawa Tengah. ",
    url: "https://www.behance.net/gallery/60858759/Lapor-Gub-(Mobile-App)",
  },
  {
    imgUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7b25b060858703.5a5bf3ed34959.png",
    title: "Loenpia Jazz 2015 - Web",
    caption:
      "Loenpia Jazz merupakan Festival musik Jazz terbesar di Semarang. ",
    url: "https://www.behance.net/gallery/60858703/Loenpia-Jazz-2014-(Website)",
  },
  {
    imgUrl: "https://cldup.com/iHsG8zfWQf.png",
    title: "Transaksi Penjualan Bukalapak",
    caption: "Coming Soon",
    url: "#",
  },
  {
    imgUrl: "https://cldup.com/iHsG8zfWQf.png",
    title: "Bukalapak Design Toolkit",
    caption:
      "Coming Soon",
    url: "/kk",
  },
]

export default projects;