import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  display: flex;
  text-decoration: none;
  flex-direction: column;
`;

const ImgWrapper = styled.div`
  min-width: 218px;
  width: 100%;
  height: 326px;
  border-radius: 8px;
  background-position: center;
  background-image: ${props => `url('${props.imgUrl}')`};
  background-size: cover;
`

const CaptionWrapper = styled.div`
  height: 160px;
  margin: -70px 16px 0;
  background: #F5E4DB;
  box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.16);
  border-radius: 8px;
  padding: 12px;
  overflow: hidden;
`;

const CaptionTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #282F57;
`;

const CaptionDesc = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #282F57;
`;

const WindowCard = ({ caption, imgUrl, title, url }) => {
  return (
    <Wrapper href={url}>
      <ImgWrapper imgUrl={imgUrl} />
      <CaptionWrapper>
        <CaptionTitle>
          { title }
        </CaptionTitle>
        <CaptionDesc>
          {caption}
        </CaptionDesc>
      </CaptionWrapper>
    </Wrapper>
  );
}

export default WindowCard;
